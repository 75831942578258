import { Injectable } from '@angular/core';
import { Loader, LoaderOptions } from '@googlemaps/js-api-loader';

@Injectable({ providedIn: 'root' })
export class MapLoader {
  public load(options: LoaderOptions): Promise<typeof google> {
    const loader = new Loader(options);
    return loader.load();
  }
}
