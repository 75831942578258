import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { AppIconsType } from '@fc-shared/ui/icon/icons';

@Component({
  selector: 'fc-alerts',
  template: `
    <div class="alert-container {{ type }}">
      <div class="block">
        <div class="alert-icon">
          <fc-icon [icon]="iconName"></fc-icon>
        </div>
        <div class="alert-message">
          <ng-content></ng-content>
        </div>
      </div>
      <fc-icon-button
        class="close-button"
        *ngIf="closeButton"
        (click)="closeEmitter.emit()"
      >
        <fc-icon icon="icon-clear"></fc-icon>
      </fc-icon-button>
    </div>
  `,
  styleUrls: ['./alerts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AlertsComponent {
  @Input() closeButton: boolean;
  @Input() type: 'success' | 'notify' | 'warning' | 'error';
  @Output() closeEmitter = new EventEmitter<void>();
  get iconName(): AppIconsType {
    switch (this.type) {
      case 'success':
        return 'icon-check_circle';
      case 'warning':
        return 'icon-warning';
      case 'notify':
        return 'icon-cancel';
      case 'error':
        return 'icon-highlight_off';
    }
  }
}
