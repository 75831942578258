import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts.component';
import { MatIconModule } from '@angular/material/icon';
import { PaymentAlertComponent } from './payment-alert.component';
import { MatButtonModule } from '@angular/material/button';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';

@NgModule({
  declarations: [AlertsComponent, PaymentAlertComponent],
  exports: [AlertsComponent, PaymentAlertComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    IconComponent,
    IconButtonComponent,
  ],
})
export class AlertsModule {}
