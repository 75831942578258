import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MapLoader } from './map-loader.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MapResolverService {
  constructor(private service: MapLoader) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> | Promise<any> | any {
    return this.service.load({
      version: 'quarterly',
      mapIds: ['19bd4c993a57f389'],
      apiKey: environment.googleMapsApiKey,
      libraries: ['drawing', 'places', 'geometry', 'marker'],
    });
  }
}
